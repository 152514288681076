import { appConfig } from '../config/appConfig';

export function getList(trackNumber: string) {
  const apiURL = appConfig().REACT_APP_TRACK_ORDER_API || 'https://apicdqa.sandbox.charac.co.uk'; //'http://localhost:4042/api/v1/orders';
  if (trackNumber === 'unsuccessful') {
    return fetch(apiURL + '/s')
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          console.log('reach')
          throw Error(response.statusText);
        }
      }).catch(err => console.log('err', err))
  }

  if (trackNumber === 'delivered')
    return fetch(apiURL)
      .then(res => {
        return {
          data: { recipientAddress :
            {city: "Nottingham", fullName: "Andy T-W", postcode: "NG8 6AT", countryCode: "GBR", addressLine1: "Colliers Way", status : "Item Drop", trackingNumber: "QV009330812GB"} ,            
             history: [{ createdAt: '2012-07-16T00:20:00Z', orderId: 1, location: 'Rome', status: 'Delivered', description: 'Carrier:TNT', note: 'More information will be available once we have received your item and scanned it into our network.' },
          { createdAt: '2012-07-16T00:20:00Z', orderId: 1, location: 'Rome', status: 'Shipped', description: 'Carrier:TNT', note: 'TrackingNo. F975656' },
          { createdAt: '2012-07-16T00:00:00Z', orderId: 1, location: 'Rome', status: 'Ready', description: 'Waiting for Carrier' },
          { createdAt: '2012-07-14T00:00:00Z', orderId: 1, location: 'Rome', status: 'Ordered' }]}
        }
      });

  if (trackNumber === 'intransit')
    return fetch(apiURL)
      .then(res => {
        return {
          data: { recipientAddress :
            {city: "Nottingham", fullName: "Andy T-W", postcode: "NG8 6AT", countryCode: "GBR", addressLine1: "Colliers Way", status : "Item Drop", trackingNumber: "QV009330812GB"} ,
            history: [{ createdAt: '2012-07-16T00:20:00Z', orderId: 1, location: 'Rome', status: 'Shipped', description: 'Carrier:TNT', note: 'TrackingNo. F975656' },
          { createdAt: '2012-07-16T00:00:00Z', orderId: 1, location: 'Rome', status: 'Ready', description: 'Waiting for Carrier' },
          { createdAt: '2012-07-14T00:00:00Z', orderId: 1, location: 'Rome', status: 'Ordered' }]
        }}
      });

  return fetch(apiURL + '/api/v1/orders/' + trackNumber + '/spatrack')
    .then(response => {
      if (response.status >= 200 && response.status <= 299) {
        // let resp =  response.json().then(y => {
        //   return y.map((x: any) => { return { 
        //     createdAt: x.lastEventDateTime, orderId: 1, location: 'Rome', status: x.statusCategory, description: x.statusDescription, note: x.statusHelpText
        //   }});
        // });

        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
}