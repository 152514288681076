import './DonutProgress.css';
import React, { useEffect } from 'react';

interface CircularProgressBarProps {
    data: any[]
}

function DonutProgress({ data }: CircularProgressBarProps) {
    const isDelivered = data.length > 0 && data.some(x => x.status === "Delivered");
    const today = new Date()
    let tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    const circularText = (cText: string, radius: number, activeArray: any[]) => {
        let words = cText.split(",");
        let classIndex = document.getElementsByClassName("circTxt")[0];
        classIndex.innerHTML = '';
        const splitDegree = [5,70,125,245]
        words.forEach((w, ind) => {
            let origin = 10;
            w.split("").forEach((ea,i) => {
                ea = `<p class="${activeArray[ind]?'activeColor':'inactiveColor'}" style='height:${radius}px;position:absolute;transform:rotate(${splitDegree[ind] + origin}deg);transform-origin:0 100%'><span>${ea}</span></p>`;
                classIndex.innerHTML += ea;
                if(w.trim() === 'IN TRANSIT' && (i === 9 || i === 1))
                origin += 4;
                else if(w.trim() === 'DELIVERED' && (i === 3))
                origin += 5;
                else if(w.trim() === 'DELIVERED' && (i === 4))
                origin += 4;
                else
                origin += 6;
            });
        })

    }
    useEffect(() => {
        const isDelivered = data.length > 0 && data.some(x => x.status === "Delivered");
        const activeArray = [data.length > 0 && data.some(x => x.status === "Ordered"),data.length > 1,((data.length > 0 && data.some(x => x.status === "Shipped")) || isDelivered),isDelivered];
        circularText("ORDERED, READY, IN TRANSIT, DELIVERED", 130,activeArray);
    }, [data])


    return (
        <div className="circularProgressContainer">
           
            <div className="pieContainer">
                <div className="pieBackground"></div>
                <div id="pieSlice1" className="hold"><div className={data.length > 0 && data.some(x => x.status === "Ordered") ? "pie activeSlice" : "pie"}></div></div>
                <div id="pieSlice2" className="hold"><div className={data.length > 1 ? "pie activeSlice" : "pie"}></div></div>
                <div id="pieSlice3" className="hold"><div className={((data.length > 0 && data.some(x => x.status === "Shipped")) || isDelivered ) ? "pie activeSlice" : "pie"}></div></div>
                <div id="pieSlice4" className="hold"><div className={isDelivered ? "pie activeSlice" : "pie"}></div></div>
                {/* <div id="pieSlice5" className="hold"><div className="pie"></div></div> */}
                {/* <div id="pieSlice6" className="hold"><div className="pie"></div></div> */}
                <div className="innerCircle"><div className="content">
                </div></div>
                <div className='absContainer circleText' >
                    <div className="circTxt" id="test"></div>
                </div>
            </div>
           

        </div>
    );
}

export default DonutProgress