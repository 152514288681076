// export const appConfig = {"REACT_APP_TRACK_ORDER_API":"https://apiuat.sandbox.charac.co.uk","REACT_APP_GOOGLE_RECAPTCHA_KEY":"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}; //JSON.parse(process.env.REACT_APP_CONFIG);
// //export const appConfig = {"REACT_APP_TRACK_ORDER_API":"https://apiqa.sandbox.charac.co.uk","REACT_APP_GOOGLE_RECAPTCHA_KEY":"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}; //JSON.parse(process.env.REACT_APP_CONFIG);
// //export const appConfig = {"REACT_APP_TRACK_ORDER_API":"https://api.charac.co.uk","REACT_APP_GOOGLE_RECAPTCHA_KEY":"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}; //JSON.parse(process.env.REACT_APP_CONFIG);


export const appConfig = () => {
    switch(categorizeEnv(window.location.hostname)){
        case 'uat': return {"REACT_APP_TRACK_ORDER_API":"https://apiuat.sandbox.charac.co.uk","REACT_APP_GOOGLE_RECAPTCHA_KEY":"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"};
        case 'prod': return {"REACT_APP_TRACK_ORDER_API":"https://api.charac.co.uk","REACT_APP_GOOGLE_RECAPTCHA_KEY":"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"};
        default: return {"REACT_APP_TRACK_ORDER_API":"https://apiqa.sandbox.charac.co.uk","REACT_APP_GOOGLE_RECAPTCHA_KEY":"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"};
    }
    
} 

const categorizeEnv = (hostname) => {
  if (hostname.includes('qa') || hostname.includes('localhost')){
    return 'qa';
  }
  if (hostname.includes('uat')){
    return 'uat';
  }
  return 'prod';
}