import './Modal.css';

const Modal = ({ handleClose, show, children }: { handleClose: any, show: boolean, children: any }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <button type="button" aria-label="close" onClick={handleClose} className="close-button"><span className="icon-close">X</span></button>
            <section className="modal-main">
                {children}
                <button type="button" className='custom-btn' onClick={handleClose}>
                    Close
                </button>
            </section>
        </div>
    );
};

export default Modal;