import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { SearchPageCaptcha } from './SearchPageCaptcha';
import { appConfig } from '../config/appConfig';
import Modal from '../components/Modal';

const reCaptchaKey = appConfig().REACT_APP_GOOGLE_RECAPTCHA_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export default function SearchPage() {
    const [searchboxValue, setSearchboxValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [keyCaptcha, setKeyCaptcha] = useState(0);
    const [isSearchError, setSearchError] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const recaptchaRef = React.useRef<ReCAPTCHA>(null);
    const onSubmitWithReCAPTCHA = async () => {
        if (searchboxValue.length < 4)
            setSearchError(true)
        else {
            setIsLoading(true)
            if (recaptchaRef?.current) {
                const token = await recaptchaRef?.current?.executeAsync();
                if (token)
                    navigate(`/search?track=${searchboxValue}`)
                recaptchaRef?.current?.reset();
            }
            else navigate(`/search?track=${searchboxValue}`)
            setIsLoading(false)
        }
        console.log(recaptchaRef)
        // apply to form data
    }
    useEffect(() => {
        setKeyCaptcha(Math.random() * 100)
        //recaptchaRef?.current?.reset();
    }, []);

    // const { search } = useLocation();
    // const match = search.match(/type=(.*)/);
    // const type = match?.[1];
    const navigate = useNavigate();

    const onChange = (value: string | null) => {
        console.log("Captcha value:", value);
        //recaptchaRef?.current?.reset();
    }

    return (
        <div className='centerContent'>
            <div className='contentBackground dflex'>

                <div className='pd-row'>
                    <h2> Track your order </h2>
                    <div className='pd-row'></div>
                </div>
                <div className='hrline'></div>
                <div className='pd-row'></div>
                <div className="dflex wd100 ai-start secondary">
                    <p> Enter your order reference*</p>
                </div>

                <div className={isSearchError ? "error input-group" : "input-group"}>

                    <div className="search-box">
                        <input className="search-input" type="text" onChange={(e) => setSearchboxValue(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    onSubmitWithReCAPTCHA();
                                }
                            }}
                            placeholder="Order reference.." />
                        <button className="search-btn" onClick={() => onSubmitWithReCAPTCHA()}>
                            <div className="search-icon">
                                &#9906;
                            </div>
                        </button>
                    </div>
                    <div className="error-message">Invalid tracking reference is entered.</div>
                </div>
                <div className="dflex wd100 ai-start">
                    <button className="track-btn" disabled={isLoading} onClick={() => onSubmitWithReCAPTCHA()}>
                        {!isLoading &&
                            "Track your order reference"
                        }
                        {isLoading &&
                            <div className="loading-spinner">
                            </div>
                        }
                    </button>
                </div>
                <div className="dflex wd100 ai-start">
                    <button onClick={() => setIsModalVisible(true)} className='anchorButton hyperText'>Need help with your order reference?</button>
                </div>
                <Modal show={isModalVisible} handleClose={() => setIsModalVisible(false)}>
                    <div className="modal-content"><h1 id="dialogTitle">I need help with my order reference</h1>
                        <h2>Why isn’t my order reference recognised by your application?</h2>
                        <p>There could be a number of reasons for this.</p>
                        <ul><li>Please check you've entered the order reference correctly.</li>
                            <li>If you're the recipient, check the your orders section to get the right order reference.</li>
                        </ul>
                        <h2>How do I find my order reference?</h2>
                        <p>Your order reference is between 6 and 27 characters long. You’ll find it on your order list.</p>
                    </div>
                </Modal>
                <SearchPageCaptcha
                    keyCaptcha={keyCaptcha}
                    reCaptchaKey={reCaptchaKey}
                    recaptchaRef={recaptchaRef}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}