import './TrackTable.css'
import { formatDate } from '../utils/dateFormat';

interface TrackTableProps {
  text: string;
  important: boolean;
  data: any[]
}

function TrackTable({ text, important, data }: TrackTableProps) {
  return (
    <div className="container-table100">
      <div className="wrap-table100">
        <div className="table100">
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr className="table100-head">
                <th className="column1">Date</th>
                <th className="column2">Location</th>
                <th className="column3">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ?
                <tr>
                  <td colSpan={3} className="column1">No record found</td>
                </tr>
                :
                data.map((x, index) => {
                  return <tr key={index}>
                    <td className="column1">{formatDate(x.createdAt)}</td>
                    <td className="column2">{x.location}</td>
                    <td className="column3">
                      <p><b>{x.status}</b></p>
                      {x.description && <p>{x.description}</p>}
                      {x.note && <p>{x.note}</p>}
                    </td>
                  </tr>
                })

              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TrackTable