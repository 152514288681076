import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DonutProgress from '../components/DonutProgress';
import TrackTable from '../components/TrackTable';
import { getList } from '../services/tracking';

interface List {
  createdAt: string
  orderId: number
  location: string
  status: string
}

export default function ResultPage() {
  const [list, setList] = useState<List[] | []>([]);
  const [orderInfo, setOrderInfo] = useState<any>(null);
  const [currentOrderIdentifier, setcurrentOrderIdentifier] = useState<string>('');
  const [isSearchError, setSearchError] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let mounted = true;
    console.log("First call on mount..");
    const orderIdentifier = searchParams.get("track");
    setcurrentOrderIdentifier(orderIdentifier ?? '');
    if (!orderIdentifier) {
      navigate('/')
    }
    getList(orderIdentifier || '')
      .then(items => {
        if (mounted) {
          let newItems = items.data && items.data.history ? items.data.history.reduce((c: any, n: any) =>
            c.find((el: any) => el.status === n.status) ? c : [...c, n], []) : [];
          newItems = newItems.map((x: any) => { return { ...x, status: x.status === "We've got it" ? "Ready" : x.status } })
          setList(newItems)
          setOrderInfo(items.data)
        }
      }).catch(err => {
        console.log(err)
        setSearchError(true)
      })
    return () => { mounted = false; }
  }, [])


  // const { search } = useLocation();
  // const match = search.match(/type=(.*)/);
  // const type = match?.[1];

  return (
    <div>
      {!isSearchError ?
        <div className="dflex">
          <div className='pd-row'>&nbsp;</div>
          <div className='pd-row'></div>
          <div className='dflex-row-equal'>
            
            <div className="dflex-row wd50">
              <div className="dflex ai-start">
                <h4 className='pb5'> Order Reference</h4>
                <div className='pd-row'>
                  <h5 className='secondary font14'> {currentOrderIdentifier}</h5>
                </div>
                <p className='pd-row'></p>
                <p className='pd-row font12'>Order Status: <span className='primary font-bold'>{orderInfo?.status}</span></p>
                <p className='pd-row font12'>Packages in this order: 1</p>
                <p className='pd-row font12'>Total Items: 1</p>
              </div>
            
              {/* <div className="dflex wd33 ai-start">
                &nbsp;
              </div> */}
            </div>
            <div className="dflex-row absWrap">
              {/* <div>
                <h2 className="secondary"> </h2>
                <div className='pd-row'>
                </div>
              </div> */}
              <div className=''>  <DonutProgress data={list} />
              </div>
            </div>
          </div>

          {/* <div className='pd-row'>&nbsp;</div>
          <div className='pd-row'>&nbsp;</div>
          <div className='pd-row'>&nbsp;</div> */}
          {/* <div className='linearProgressWrap'>
            <ProgressBar data={list} />
          </div> */}
          <div className="dflexSeperator">
            <h3>History</h3>
          </div>
          <TrackTable text="Progressbar" important={true} data={list} />
        </div>
        :
        <div className="dflex">
          <div className="title">
            <h2>Track your order</h2>
          </div>
          <div className="error-notice">
            <div className="oaerror danger">
              <strong>Error</strong>- You have search for incorrect order reference. Please try again.
            </div>
          </div>
        </div>
      }
      <div className={`${isSearchError ? "backbtnContainer backbtnContainerMargin" : "backbtnContainer"}`}>
        <button className='custom-btn' onClick={() => navigate('/')}>
          Back to Search
        </button>
      </div>
    </div>
  );
}