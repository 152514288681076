// import React, { useCallback, useEffect } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// export const YourReCaptchaComponent = () => {
//     const { executeRecaptcha } = useGoogleReCaptcha();

//     // Create an event handler so you can call the verification on button click event or form submit
//     const handleReCaptchaVerify = useCallback(async () => {
//         if (!executeRecaptcha) {
//             console.log('Execute recaptcha not yet available');
//             return;
//         }

//         const token = await executeRecaptcha('yourAction');
//         // Do whatever you want with the token
//     }, [executeRecaptcha]);

//     // You can use useEffect to trigger the verification as soon as the component being loaded
//     useEffect(() => {
//         handleReCaptchaVerify();
//     }, [handleReCaptchaVerify]);

//     return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
// };

import ReCAPTCHA from 'react-google-recaptcha';
export const SearchPageCaptcha = ({keyCaptcha,reCaptchaKey,recaptchaRef,onChange}:any) => {
    return (<ReCAPTCHA
        key={keyCaptcha}
        sitekey={reCaptchaKey}
        ref={recaptchaRef}
        size="invisible"
        onChange={onChange}
        onExpired={() => {
            recaptchaRef?.current?.reset();
          }}
    />)
}