import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import SearchPage from './pages/SearchPage';
import ResultPage from './pages/ResultPage';
import HealthCheck from './pages/HealthCheck';

function App() {
  const [brandName, setBrandName] = useState('Charac'); //pilltime
  
    useEffect(() => {
      // const currentHostBrand = window.location.hostname.split('.')[0]
      // .replace('trackingqa', '').replace('trackinguat', '')
      // .replace('qatracking', '').replace('uattracking', '')
      // .replace('tracking', '');
      let currentHostBrand = window.location.hostname.split('.')[0]
      .replace('trackingqa', '').replace('trackinguat', '')
      .replace('qatracking', '').replace('uattracking', '')
      .replace('tracking', '');
      if(!currentHostBrand) currentHostBrand = window.location.hostname.split('.')[1];
      switch (currentHostBrand) {
        case 'psl': setBrandName('psl');window.document.title = "myhealthhub" ; break;
        case 'pearlchemistgroup': setBrandName('pearlchemistgroup');window.document.title = "PearlChemistGroup" ; break;
        case 'plashetpharmacy': setBrandName('plashetpharmacy');window.document.title = "plashetpharmacy" ; break;
        case 'thelocalpharmacy': setBrandName('thelocalpharmacy');window.document.title = "thelocalpharmacy" ; break;
        case 'whitworthchemists': setBrandName('whitworthchemists');window.document.title = "whitworthchemists" ; break;
        case 'pharmacy-xpress': setBrandName('pharmacy-xpress');window.document.title = "pharmacy-xpress" ; break;
        case 'imaanpharmacy': setBrandName('imaanpharmacy');window.document.title = "imaanpharmacy" ; break;
        case 'touchwoodpharmacy': setBrandName('touchwoodpharmacy');window.document.title = "touchwoodpharmacy" ; break;
        case 'pinxtonpharmacy': setBrandName('pinxtonpharmacy');window.document.title = "pinxtonpharmacy" ; break;
        case 'jhootspharmacy': setBrandName('jhootspharmacy');window.document.title = "jhootspharmacy" ; break;
        case 'werrington-pharmacy': setBrandName('werrington-pharmacy');window.document.title = "werrington-pharmacy" ; break;
        case 'pilltime': setBrandName('pilltime');window.document.title = "pilltime" ; break;
        case 'inspirepharmacy': setBrandName('inspirepharmacy');window.document.title = "inspirepharmacy" ; break;
        case 'jadepharmacy': setBrandName('jadepharmacy');window.document.title = "jadepharmacy" ; break;
        case 'tudapharmacy': setBrandName('tudapharmacy');window.document.title = "tudapharmacy" ; break;
        case 'maltingspharmacy': setBrandName('maltingspharmacy');window.document.title = "maltingspharmacy" ; break;
        case 'hilltopspharmacy': setBrandName('hilltopspharmacy');window.document.title = "hilltopspharmacy" ; break;
        case 'hodgsonpharmacy': setBrandName('hodgsonpharmacy');window.document.title = "hodgsonpharmacy" ; break;
        case 'lincolnpharmacy': setBrandName('lincolnpharmacy');window.document.title = "lincolnpharmacy" ; break;
        case 'bonamypharmacy': setBrandName('bonamypharmacy');window.document.title = "bonamypharmacy" ; break;
        case 'imaanhealthcare': setBrandName('imaanhealthcare');window.document.title = "imaanhealthcare" ; break;
        case 'lansdalespharmacy': setBrandName('lansdalespharmacy');window.document.title = "lansdalespharmacy" ; break;
        case 'westburychemist': setBrandName('westburychemist');window.document.title = "westburychemist" ; break;
        case 'bullens': setBrandName('bullens');window.document.title = "bullens" ; break;
        case 'annapharmacy': setBrandName('annapharmacy');window.document.title = "annapharmacy" ; break;
        case 'christchurchhealthcentre': setBrandName('christchurchhealthcentre');window.document.title = "christchurchhealthcentre" ; break;
        case 'jardinespharmacy': setBrandName('jardinespharmacy');window.document.title = "jardinespharmacy" ; break;
        default: setBrandName('Charac');
      }
      updateFaviconIcon(currentHostBrand);
    }, []);
  
    function updateFaviconIcon(brandName: string) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = `https://webassetsqa.sandbox.charac.co.uk/${brandName}/assets/icon/favicons/apple-touch-icon.png`;
    }

  return (
    <div className="App">
      <div className="dflex mt-2">
        <div className="companytitle">
          <a href="/"> <img alt="Logo" src={`https://webassetsqa.sandbox.charac.co.uk/${brandName}/assets/images/characLogo.svg`} /></a>
          <link rel="stylesheet" type="text/css" href={`https://webassetsqa.sandbox.charac.co.uk/${brandName}/main.css`} />
        </div>
      </div>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route path="/search" element={<ResultPage />} />
          <Route path="/api/v1/healthCheck" element={<HealthCheck />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
